// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#8F6284',
        secondary: '#EB6510',
        accent: '#EB6510',
        error: '#F44336',
        info: '#613757',
        success: '#4CAF50',
        warning: '#DEA85F',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    //
  },
});
